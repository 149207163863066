import React, { useState, useEffect } from 'react';
import { Grid, Box, Fab, Input, IconButton, Card, CardHeader, CircularProgress } from '@mui/material';
import ThemeColor from '../../../../components/Theme/Theme';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import Button from '../../../../components/ButtonComponent/Button';
import Alerta from '../../../../components/AlertaComponent/Alerta';
import { handleShowSpinner, handleShowSpinnerStop } from '../../PaginaInicio';
import { UploadImage } from '../../../../API/UploadImage';
import {GetResponseAPI} from '../../../../API/GetResponseAPI';
import {UpdateConsultConuntAPI} from '../../../../API/ConsultsCountAPI';
import {ConsultarPerfil} from '../../../../API/Perfil';
import {UpdateCountAPI} from '../../../../layouts/FullLayout/Header/Header'
import ImageCompressor from '../../../../components/comressingImgComponent/CompressingImg';
import OpcionesAdicionales from '../OpcionesAdicionales';
import ClearIcon from '@mui/icons-material/ClearOutlined';

const PrompComplete = () => {
    const [tipo, setTipo] = useState("");
    const [habitacion, setHabitacion] = useState("");
    const [changeArea, setChangeArea] = useState(null);
    const [estilo, setEstilo] = useState("");
    const [decoracion, setDecoracion] = useState(null);
    const [presupuesto, setPresupuesto] = useState("");
    const [colorPredominandte, setColorPredominante] = useState("");
    const [colores, setColores] = useState(null);
  
  
    const [initImage, setInitImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const email  = sessionStorage.getItem('correo');
    const [consultCount, setConsultCount]= useState("");

    useEffect(()=> {
        ConsultCountAPI();
    },[]);

    //Función para verificar el # de consultas realizadas al API roomInterior
    const ConsultCountAPI = async()=>{
        try{  
          const {usuario, success} = await ConsultarPerfil(email);
          if(!success){
                setConsultCount(usuario.consult);
              return;
            }else{
              setConsultCount("0");
            }
      }catch(err){
          return;
      }
    
      };

    updateTipo = (value) => {
        setTipo(value);
    };
    updateHabitacion = (value) => {
        setHabitacion(value);
    };
    updateChangeArea = (value) =>{
        setChangeArea(value);
    };
    updateEstilo = (value) => {
        setEstilo(value);
    };
    updateDecoracion = (value) => {
        setDecoracion(value);
    };
    updatePresupuesto = (value) => {
        setPresupuesto(value);
    };
    updateColorPredominante = (value) => {
        setColorPredominante(value);
    };
    updateColores = (value) => {
        setColores(value);
    };
    hanldeCleanPromp = () => {
        setTipo("");
        setChangeArea("");
        setHabitacion("");
        setEstilo("");
        setDecoracion("");
        setPresupuesto("");
        setColorPredominante("");
        setColores("");
    };

    

    //const dataDecoracion = decoracion.map((item) => item);
    //const dataColores = colores.map((item) => item);
    const negativpromp =`${tipo === 'empty the room of furniture' ? 'furniture, plants, decorations, beds, carpets, objects, couchs.':''}`;
    const promp =`  
                    ${tipo ==="recomended" ? `Create a  ${tipo} desing for redecorating a ${habitacion} `:''}
                    ${tipo ==="Redecorate" ? `${tipo} a ${habitacion} `:''}  
                    ${tipo === "Empty space" ? `Generate image of empty ${habitacion} while preserving original floor and structure, `:""}
                    ${tipo === 'color change' ? `${changeArea ? `Select and replace color ${changeArea}, `: '' }`:'' || tipo === 'improve and replace' ?`${changeArea ? `Select replace and upgrade new ${changeArea}, `: '' }`:''} 
                    ${tipo === "Drawings and Renders" ? `Generate a detailed and photorealistic rendering of a ${habitacion} `:""}
                    ${tipo ==="empty the room of furniture" ? `Generate ${habitacion} empty walls, floor, ceiling, windows, doors, no furniture, natural light, highlight textures, `:''} 
                    ${tipo === 'furnitures staging' ? `Furnish a ${habitacion}`:''}  
                    ${habitacion ==='facade' ? `Modernizes facade, clean, efficient and takes into account the material of the initial structure`:'' }
                    ${estilo ? ` in ${estilo} style, `: '' } 
                    ${decoracion ? `add ${decoracion} as appropriate decoration, `:''  } 
                    ${colorPredominandte ? `use a color palette  ${colorPredominandte} `: '' } 
                    ${colores ? `integrate ${colores} in balance with existing colors of the environment, `:'' } 
                    ${presupuesto ? `design according to ${presupuesto} budge .`: '' }
                    Rendering in 4K hyper-realistic textures, lighting and colors`;
                    //console.log('Promp:',promp);
    //const promp = `Tipo Fotografia ${foto}, a una habitacion de tipo ${habitacion} usa un estilo ${estilo}, usa estas decoraciones ${dataDecoracion}, usa este estilo de color ${colorPredominandte}, con los siguientes colores ${dataColores}, con un presupuesto de remodelacion ${presupuesto}`;
    const options = `Tipo:${tipo}, ${changeArea ? `Elemento:${changeArea},`:''} ${habitacion ? `Entorno:${habitacion},`: ''} ${estilo ? `Estilo:${estilo},` : ''} ${decoracion ? `Decoraciones:${decoracion},`: ''} ${colorPredominandte ? `Color Predominante:${colorPredominandte},` :''} ${colores ? `Colores:${colores},` :''} ${presupuesto ? `Presupuesto:${presupuesto}.` :''}`;
    {/*---- Funcion para poder validar el promp para que no sea null ----*/ }
    const EnviarInformacion = async (e) => {
        e.preventDefault();
       
        if(consultCount >= 40){
            MostrarAlerta();
            setMessage("Has alcanzado el límite de 40 consultas permitidas por mes. Te esperamos el siguiente mes!.");
            setStyle("error");
            return;
        }
        if (tipo === '') {
            setError(true);
            MostrarAlerta();
            setMessage(" Debes de seleccionar un tipo de acción");
            setStyle("error");
            return;
        }
        if (habitacion === '') {
            setError(true);
            MostrarAlerta();
            setMessage(" Debes de definir un entorno");
            setStyle("error");
            return;
        }
        if (initImage === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debes agregar una imagen en formto jpg/png");
            setStyle("error");
            return;
        }

        handleShowSpinner();
        // Enviamos la información...
        try{ 
          const {consult} = await GetResponseAPI(initImage, promp, negativpromp, options );
            if(consult){
                await UpdateConsultConuntAPI(email);
                const {usuario, success} = await ConsultarPerfil(email);
                    if(!success){
                            UpdateCountAPI(usuario.consult);
                            setConsultCount(usuario.consult);
                        return;
                        }
            }
        }catch(error){
            return alert('Esta imagen desafortunadamente no podemos identificarla, prueba con otra o intentalo de nuevo');
        }
        //Limpiar los campos despues de enviar los datos
        setError(false);
        setInitImage(" ");
        hanldeCleanPromp();

    };

    //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
        }, 3000)
    };

    const hanldeImageUpload = async (event) =>{
        const file = event.target.files[0];
        if(!file) return;
        setLoading(true);

        try{
            const processed = await ImageCompressor(file);
            const base64Image = await convertToBase64(processed);
            //console.log('Tamaño original:', (file.size / 1024).toFixed(2), 'KB');
            //console.log('Tamaño procesado:', (processed.size/ 1024).toFixed(2), 'Kb');
            await handleToServer(base64Image);
        }catch(err){
            
        }finally{
            setLoading(false);
        }
    };

    //Convertir a base64
    const convertToBase64 = (file) =>{
        return new Promise((resolve, reject)=> {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    //Subir al servidor
    const handleToServer = async (base64Image) =>{
        try{ 
            const {image,success, messageErr, styleErr} = await UploadImage(base64Image);
            if (success) {
              MostrarAlerta();
              setMessage(messageErr);
              setStyle(styleErr);
              if(image){
                  setInitImage(image);
              }
              return;
            }
          }catch(error){
            MostrarAlerta();
            setMessage('Error al procesar o enviar la imagen');
             setStyle('error');
          }
    }

    const handleClearImage =()=>{
        setInitImage("");
    };

    return (
        <div>
            { loading && <Card sx={{
                    width:'310px',
                    height:'245px',

                }}>
                <CardHeader
                    action={
                        <IconButton  aria-label="cleaar"  color="info" onClick={handleClearImage}>
                            <ClearIcon/>
                        </IconButton>
                    }
                    title='Subiendo Imagen'
                />
                <Box
                    sx={{
                        width:'310px',
                        height:'245px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center'
                    }}
                
                >
                <CircularProgress />
                </Box>
               
                </Card>
            
            }
            { initImage &&<Card sx={{
                    width:'310px',
                    height:'245px',
                }}>
                <CardHeader
                    action={
                        <IconButton  aria-label="cleaar"  color="info" onClick={handleClearImage}>
                            <ClearIcon/>
                        </IconButton>
                    }
                    title='Imagen seleccionada'
                />
                <img width={300} height={200} src={initImage} alt="Selected image valid"  />
            
            </Card>
            }
           

            <OpcionesAdicionales>

                <Grid container  direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}
                    sx={{paddingRight:"5%"}}    
                >
                    <Grid item >
                    <Box>
                        <ThemeColor>
                        <Fab
                            color="red"
                            onClick={() => {
                                document.getElementById('image-input').click();
                            }}
                            sx={{
                                ":hover":{
                                    backgroundColor: "#C21F22"
                                },
                                height:"200%",
                                width:"150%",
                                mr: 1,
                                mb: {
                                    xs: 1,
                                    sm: 0,
                                    lg: 0,
                                    margin:'auto',
                                },
                            }}
                        >
                            <ImageOutlinedIcon color="white" />
                        </Fab >
                        <Input
                            type="file"
                            inputProps={{
                                accept: 'image/jpeg, image/png',
                            }}
                            id="image-input"
                            style={{ display: 'none' }}
                            onChange={hanldeImageUpload}
                        />
                        </ThemeColor>
                        
                    </Box>
                    </Grid>

                  

                    <Grid item>
                        <ThemeColor>
                            <Button  color="orange" hover="#D65A1A"  hadleAction={EnviarInformacion}>
                                <SendOutlinedIcon color="white" />
                            </Button>
                        </ThemeColor>
                    </Grid>
                    
                </Grid>

            </OpcionesAdicionales>


            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
        </div>

    );
}
export default PrompComplete;
export function updateTipo() { };
export function updateChangeArea() { };
export function updateHabitacion() { };
export function updateEstilo() { };
export function updateDecoracion() { };
export function updatePresupuesto() { };
export function updateColorPredominante() { };
export function updateColores() { };
export function hanldeCleanPromp() { };